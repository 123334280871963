<script lang="ts">
	import { Avatar, DropdownMenu } from 'bits-ui';
	import { UserCircle, SignOut, GearSix, ShieldStar } from 'phosphor-svelte';
	import { cubicOut } from 'svelte/easing';
	import type { TransitionConfig } from 'svelte/transition';
	import User from '../player/User.svelte';
	import Icons from '../Icons.svelte';
	import { user } from '$lib/stores/user';
	import { toast } from 'svelte-sonner';
	import { DeprecatedFetch_useapiFetch } from '$lib/utils/fetch';
	import { is_admin, permissions } from '$lib/stores/permissions';
	import posthog from 'posthog-js';

	type FlyAndScaleParams = {
		y?: number;
		start?: number;
		duration?: number;
	};

	async function handleLogout() {
		await DeprecatedFetch_useapiFetch({
			endpoint: '/auth/deauth',
			method: 'PUT',
			body: {}
		})
			.then(() => {
				toast.success('Du har loggats ut');
				user.set(null);
				permissions.set(null);
				posthog.reset();
			})
			.catch(() => {
				user.set(null);
			});
	}

	const defaultFlyAndScaleParams = { y: -8, start: 0.95, duration: 200 };

	export function flyAndScale(node: Element, params?: FlyAndScaleParams): TransitionConfig {
		const style = getComputedStyle(node);
		const transform = style.transform === 'none' ? '' : style.transform;
		const withDefaults = { ...defaultFlyAndScaleParams, ...params };

		const scaleConversion = (
			valueA: number,
			scaleA: [number, number],
			scaleB: [number, number]
		) => {
			const [minA, maxA] = scaleA;
			const [minB, maxB] = scaleB;

			const percentage = (valueA - minA) / (maxA - minA);
			const valueB = percentage * (maxB - minB) + minB;

			return valueB;
		};

		const styleToString = (style: Record<string, number | string | undefined>): string => {
			return Object.keys(style).reduce((str, key) => {
				if (style[key] === undefined) return str;
				return `${str}${key}:${style[key]};`;
			}, '');
		};

		return {
			duration: withDefaults.duration ?? 200,
			delay: 0,
			css: (t) => {
				const y = scaleConversion(t, [0, 1], [withDefaults.y, 0]);
				const scale = scaleConversion(t, [0, 1], [withDefaults.start, 1]);

				return styleToString({
					transform: `${transform} translate3d(0, ${y}px, 0) scale(${scale})`,
					opacity: t
				});
			},
			easing: cubicOut
		};
	}
</script>

<DropdownMenu.Root>
	<DropdownMenu.Trigger class="flex flex-row items-center p-1 rounded-lg hover:bg-zinc-600/20">
		<div class="mr-2">
			<User props={$user} disabled={true} display_name={false} avatar_size="smallest" />
		</div>
		<div class="flex items-center">
			<span>{$user?.username}</span>
			<Icons name="chevrondown" size="18px" />
		</div>
	</DropdownMenu.Trigger>
	<DropdownMenu.Content
		class="w-full max-w-[229px] rounded-lg border border-zinc-800 bg-backgrounddarker px-1 py-1"
		sideOffset={8}
		transition={flyAndScale}
	>
		<DropdownMenu.Item
			class="flex h-10 select-none items-center py-3 pl-3 pr-1.5 text-sm font-medium !ring-0 !ring-transparent data-[highlighted]:bg-zinc-800 rounded-lg "
			href="/profile/{$user?.username}"
		>
			<div class="flex items-center text-zinc-100">
				<UserCircle class="w-5 h-5 mr-2" />
				Profil
			</div>
		</DropdownMenu.Item>
		{#if $is_admin}
			<DropdownMenu.Item
				class="flex h-10 select-none items-center py-3 pl-3 pr-1.5 text-sm font-medium !ring-0 !ring-transparent data-[highlighted]:bg-zinc-800 rounded-lg "
				href="/admin"
			>
				<div class="flex items-center text-zinc-100">
					<ShieldStar class="w-5 h-5 mr-2" />
					Admin
				</div>
			</DropdownMenu.Item>
		{/if}
		<DropdownMenu.Item
			class="flex h-10 select-none items-center py-3 pl-3 pr-1.5 text-sm font-medium !ring-0 !ring-transparent data-[highlighted]:bg-zinc-800 rounded-lg "
			href="/profile/edit"
		>
			<div class="flex items-center text-zinc-100">
				<GearSix class="w-5 h-5 mr-2" />
				Inställningar
			</div>
		</DropdownMenu.Item>
		<DropdownMenu.Separator class="block h-px my-1 -ml-1 -mr-1 bg-zinc-800" />
		<DropdownMenu.Item
			class="flex h-10 select-none items-center py-3 pl-3 pr-1.5 text-sm font-medium !ring-0 !ring-transparent data-[highlighted]:bg-red-600/70 rounded-lg  cursor-pointer"
			on:click={handleLogout}
		>
			<div class="flex items-center text-zinc-100">
				<SignOut class="w-5 h-5 mr-2" />
				Logga ut
			</div>
		</DropdownMenu.Item>
	</DropdownMenu.Content>
</DropdownMenu.Root>
